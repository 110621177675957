import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Collapse,
  Box,
  TablePagination,
} from '@mui/material';
import { KeyboardArrowDown as KeyboardArrowDownIcon, KeyboardArrowUp as KeyboardArrowUpIcon, Delete as DeleteIcon } from '@mui/icons-material';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import './audioPlayerStyles.css';
import ConfirmDeleteDialog from './ConfirmDeleteDialog';

const djangoBaseUrl = process.env.REACT_APP_DJANGO_BASE_URL;

function formatDate(dateString) {
  const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true };
  return new Date(dateString).toLocaleDateString('en-US', options);
}

function Row({ row, onDelete }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
      <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.id}
        </TableCell>
        <TableCell>{formatDate(row.created_at)}</TableCell>
        <TableCell>{row.duration}</TableCell>
        <TableCell>{row.is_transcribed ? 'Yes' : 'No'}</TableCell>
        <TableCell>
          <AudioPlayer
            src={row.audio_file_url}
            onPlay={e => console.log("onPlay")}
            layout='horizontal-reverse'
            showSkipControls={false}
            showJumpControls={false}
            customVolumeControls={[]}
            customAdditionalControls={[]}
            autoPlayAfterSrcChange={false}
            defaultCurrentTime={''}
            defaultDuration={''}
            timeFormat={'mm:ss'}
            customProgressBarSection={['CURRENT_TIME', 'PROGRESS_BAR']}
          />
        </TableCell>
        <TableCell>
          <IconButton onClick={() => onDelete(row.id)}>
            <DeleteIcon style={{ color: '#f44336' }}/>
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              {open && <RowDetails esDocumentId={row.es_document_id} />}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

const RowDetails = ({ esDocumentId, transcription }) => {
  const [localTranscription, setLocalTranscription] = useState(transcription);
  const [isLoading, setIsLoading] = useState(false);
  const djangoBaseUrl = process.env.REACT_APP_DJANGO_BASE_URL;
  useEffect(() => {
    if (transcription) {
      setLocalTranscription(transcription);
      return;
    }
    
    const fetchTranscription = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`${djangoBaseUrl}/transcriptions/${esDocumentId}/`);
        if (response.ok) {
          const data = await response.json();
          setLocalTranscription(data.transcript);
        } else {
          console.error('Failed to fetch transcription');
          setLocalTranscription(null);
        }
      } catch (error) {
        console.error('Error fetching transcription:', error);
        setLocalTranscription(null);
      }
      setIsLoading(false);
    };

    fetchTranscription();
  }, [esDocumentId, transcription]);

  return (
    <div style={{ padding: '10px' }}>
      <strong>Transcription:</strong>
      {isLoading ? (
        <span>Loading...</span>
      ) : (
        <span>{localTranscription || '  No transcription available'}</span>
      )}
    </div>
  );
};

function LogsTable({ logs, setLogs }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const authToken = localStorage.getItem('token');

  const handleOpenDialog = (id) => {
    setSelectedRowId(id);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleConfirmDelete = async () => {
    if (selectedRowId !== null) {
      try {
        const response = await fetch(`${djangoBaseUrl}/audio_log/${selectedRowId}/`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Token ${authToken}`
          },
        });

        if (!response.ok) {
          throw new Error('Failed to delete the audio log.');
        }

        setLogs(logs.filter((log) => log.id !== selectedRowId));
      } catch (error) {
        console.error('Error deleting the audio log:', error);
      }

      handleCloseDialog();
    }
  };

  const handleDelete = (id) => {
    setLogs(logs.filter((log) => log.id !== id));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>ID</TableCell>
              <TableCell>Created Time</TableCell>
              <TableCell>Duration</TableCell>
              <TableCell>Is Transcribed</TableCell>
              <TableCell>Audio Player</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {logs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((log) => (
              <Row key={log.id} row={log} onDelete={() => handleOpenDialog(log.id)} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={logs.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <ConfirmDeleteDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        onConfirm={handleConfirmDelete}
      />
    </Paper>
    
  );
}

export default LogsTable;
