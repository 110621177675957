import React, { useCallback, useEffect, useState } from 'react';
import { useVoiceVisualizer, VoiceVisualizer } from 'react-voice-visualizer';
import TranscriptionBox from './TranscriptionBox';
import LogsTable from './LogsTable';
import styles from './Home.module.css'

const Home = () => {
  const recorderControls = useVoiceVisualizer();
  const {
    recordedBlob,
    startRecording: originalStartRecording,
    stopRecording: originalStopRecording,
    error,
    audioRef,
  } = recorderControls;

  const [audioUrl, setAudioUrl] = useState(null);
  const [transcriptionText, setTranscriptionText] = useState('');
  const [logs, setLogs] = useState([]);
  const [startTime, setStartTime] = useState(0);
  const [endTime, setEndTime] = useState(0);
  const djangoBaseUrl = process.env.REACT_APP_DJANGO_BASE_URL;

  const startRecording = () => {
    setStartTime(new Date().getTime());
    originalStartRecording();
  };

  const stopRecording = () => {
    setEndTime(new Date().getTime());
    originalStopRecording();
  };

  const uploadAudioLog = useCallback(async (fileName, duration, transcript, esDocumentId) => {
    const authToken = localStorage.getItem('token');
  
    const formData = new FormData();
    formData.append('file', recordedBlob, fileName);
    formData.append('file_name', fileName);
    formData.append('duration', duration);
    formData.append('transcription', transcript);
    formData.append('is_transcribed', true);
    formData.append('es_document_id', esDocumentId);
    try {
      const response = await fetch(`${djangoBaseUrl}/audio_logs/`, {
        method: 'POST',
        headers: {
          'Authorization': `Token ${authToken}`
        },
        body: formData,
      });
  
      if (!response.ok) {
        throw new Error('Failed to upload audio log');
      }
  
      const result = await response.json();
      fetchLogs()
    } catch (error) {
      console.error('Error uploading audio log:', error);
    }
  }, [recordedBlob]);

  const uploadAudioForTranscription = useCallback(async (blob) => {
    const authToken = localStorage.getItem('token');
    const durationInSeconds = (endTime - startTime) / 1000;
    const formData = new FormData();
    const now = new Date();
    const formattedDate = now.toISOString().replace(/[-:.]/g, '').replace(/\..*$/, '');
    formData.append('file', blob, `recording-${formattedDate}.wav`);

    try {
      const response = await fetch(`${djangoBaseUrl}/transcribe`, {
        method: 'POST',
        headers: {
          'Authorization': `Token ${authToken}`
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to transcribe audio');
      }
      
      if (response.ok) {
        const result = await response.json();
        const transcript = result.transcript;
        const esDocumentId = result.es_document_id;
        setTranscriptionText(transcript);
        const username = localStorage.getItem('username');
        const timestamp = new Date().toISOString().replace(/[-:.]/g, '');
        const fileName = `${username}_${timestamp}.wav`;
  
        uploadAudioLog(fileName, durationInSeconds, transcript, esDocumentId);
      }
    } catch (error) {
      console.error('Error uploading and transcribing file:', error);
    }
  }, [startTime, endTime, uploadAudioLog]);

  const fetchLogs = useCallback(async () => {
    
    try {
      const token = localStorage.getItem('token')
      const response = await fetch(`${djangoBaseUrl}/audio_logs/`, {
        method: 'GET',
        headers: {
          'Authorization': `Token ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch logs');
      }

      const data = await response.json();
      setLogs(data);
    } catch (error) {
      console.error('Error fetching logs:', error);
    }
  }, []);

  useEffect(() => {
    if (!recordedBlob) return;

    uploadAudioForTranscription(recordedBlob);
    setAudioUrl(URL.createObjectURL(recordedBlob));
  }, [recordedBlob, uploadAudioForTranscription]);

  useEffect(() => {
    fetchLogs();
  }, [fetchLogs]);

  useEffect(() => {
    if (!error) return;
    console.error(error);
  }, [error]);

  return (
    <div className={styles.homeContainer}>
      <div className={styles.main}>
        <div className={styles.recordingSection}>
          <VoiceVisualizer 
            ref={audioRef} 
            controls={{ ...recorderControls, startRecording, stopRecording }}
            mainBarColor={'#616161'}
            width={'100%'}
            height={'200'}
            barWidth={2}
            fullscreen={true}
          />
        </div>
        <div className={styles.transcriptionSection}>
          <TranscriptionBox transcriptionText={transcriptionText} />
        </div>
        <div className={styles.logsTableContainer}>
        {logs && logs.length > 0 && (
          <LogsTable logs={logs} setLogs={setLogs} />
        )}
        </div>
      </div>
    </div>
  );
};

export default Home;


