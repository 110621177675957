import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation  } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material';
import Login from './components/Login';
import Register from './components/Register';
import Home from './components/Home';
import PersistentDrawerLeft from './components/PersistentDrawerLeft';
import { AuthProvider, useAuth } from './components/AuthContext';
import Header from './components/Header';
import CssBaseline from '@mui/material/CssBaseline';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const theme = createTheme({
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
  },
});

const App = () => {
  const { authToken } = useAuth();
  const location = useLocation();
  const hideHeader = location.pathname === '/login' || location.pathname === '/register';

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {!hideHeader && <Header />}
      {!hideHeader && authToken && <PersistentDrawerLeft />}
      <Routes>
        <Route path="/login" element={!authToken ? <Login /> : <Navigate replace to="/home" />} />
        <Route path="/register" element={!authToken ? <Register /> : <Navigate replace to="/login" />} />
        <Route path="/home" element={authToken ? <Home /> : <Navigate to="/login" />} />
        <Route path="/" element={<Navigate replace to={authToken ? "/home" : "/login"} />} />
      </Routes>
    </ThemeProvider>
  );
};

const WrappedApp = () => (
  <AuthProvider>
    <Router>
      <App />
    </Router>
  </AuthProvider>
);

export default WrappedApp;
