import React from 'react';
import styles from './TranscriptionBox.module.css';

const TranscriptionBox = ({ transcriptionText }) => {
  const content = transcriptionText || "Survived another day? Hit 'Record', and let's turn today's drama into tomorrow's history.";
  const dynamicStyles = {
    color: transcriptionText ? 'black' : '#6c757d',
    fontStyle: transcriptionText ? 'normal' : 'italic',
  };

  return (
    <div
      className={styles.transcriptionBox}
      aria-label="transcription"
      role="textbox"
      tabIndex="0"
      style={dynamicStyles}
      aria-placeholder="Survived another day? Hit 'Record', and let's turn today's drama into tomorrow's history."
    >
      {transcriptionText || "Survived another day? Hit 'Record', and let's turn today's drama into tomorrow's history."}
    </div>
  );
};

export default TranscriptionBox;