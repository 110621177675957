import React, { useState, useEffect } from 'react';
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router-dom';
import styles from './Login.module.css'

const Register = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const { register, logout } = useAuth();

  useEffect(() => {
    logout();
  }, [logout]);

  const handleSubmit = (e) => {
    e.preventDefault();
    register(username, email, password)
      .then(response => {
        navigate('/login');
        console.log('Registration successful');
      })
      .catch(error => {
        console.error('Registration failed', error);
      });
  };

  return (
    <div className={styles.register_container}>
      <form onSubmit={handleSubmit} className={styles.register_form}>
        <h2>Register</h2>
        <input 
          type="text" 
          placeholder="Username" 
          value={username} 
          onChange={(e) => setUsername(e.target.value)} 
        />
        <input 
          type="password" 
          placeholder="Password" 
          value={password} 
          onChange={(e) => setPassword(e.target.value)} 
        />
        <input 
          type="email" 
          placeholder="Email" 
          value={email} 
          onChange={(e) => setEmail(e.target.value)} 
        />
        <button type="submit">Register</button>
      </form>
    </div>
  );
};

export default Register;

